const atoms = document.querySelectorAll('.atom');
const activeAtomClassName = 'is-active';
let currentAtomIndex = null;

atoms.forEach((atom, index) => {
  atom.addEventListener('click', event => selectAtom(index));
});

function selectAtom(atomIndex) {
  deselectCurrentAtom();
  const atom = atoms[atomIndex];
  atom.classList.add(activeAtomClassName);
  currentAtomIndex = atomIndex;

  const atomTitle = atom.querySelector('.atom-title').innerText;
  const atomText = atom.querySelector('.atom-text').innerText;
  const atomImage = atom.querySelector('.atom-image');

  showModal({
    title: atomTitle,
    text: atomText,
    image: atomImage ? atomImage.src : null,
    onPrev: prevAtom,
    onNext: nextAtom
  });
}

function prevAtom() {
  selectAtom(currentAtomIndex > 0 ? currentAtomIndex - 1 : atoms.length - 1);
}

function nextAtom() {
  selectAtom(currentAtomIndex < atoms.length - 1 ? currentAtomIndex + 1 : 0);
}

function deselectCurrentAtom() {

  // Loop through all atoms
  atoms.forEach(atom => {
    atom.classList.remove(activeAtomClassName);
  });

  // Unselect atom
  currentAtomIndex = null;
}

registerModalCloseCallback('atom', deselectCurrentAtom);